<template>
  <div class="home">
    <div class="logo-container"><Logo class="logo" /></div>
    <div class="header">
      <div class="title">Kick off the New Year in Style</div>
      <div class="subtitle">With our 24G Cocktail/Mocktail Menu</div>
      <div>
        Festive, effervescent and easy! Sweet, savory, and fresh! Our 24G cocktail menu has it all, the perfect start to
        your 2022. Our menu features ingredients you’d expect for this time of year (did someone say Prosecco?), plus
        some creative spins on old classics and a couple party-ready punches. These cocktails are sure to fuel your
        celebration well into the new year, a companion list for all the good times ahead. Warmest new year wishes from
        all of us at 24G!
      </div>
    </div>
    <div class="content">
      <div class="card" v-for="recipe in recipes.recipes" :key="recipe.name" @click="goToRecipe(recipe)">
        <div class="img-container">
          <img class="parent-image" :src="loadImg(recipe.front)" />
          <img class="background-image fade-in-image" :src="loadImg(recipe.back)" />
        </div>
      </div>
    </div>
    <footer>
      <a href="https://www.24g.com/" target="_blank" class="footer-link"
        >Shared with love from <span class="bold">24<span class="orange">G</span></span></a
      >
    </footer>
  </div>
</template>

<script>
import recipes from '@/assets/json/recipes.json';
import Logo from '@/assets/icons/24g_logo.svg';

const pngImages = require.context('../assets/images/', false, /.png$/);

export default {
  name: 'Home',
  components: { Logo },
  data() {
    return {
      recipes,
    };
  },
  methods: {
    loadImg(imgPath) {
      return pngImages(`./${imgPath}`);
    },
    goToRecipe(recipe) {
      this.$router.push(`/${recipe.routeName}`);
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
.logo-container {
  position: relative;
  display: flex;
  padding: 50px;
  @media only screen and (min-width: 1100px) {
    position: sticky;
    top: 0;
  }
}
.header {
  display: flex;
  flex-direction: column;
  padding: 0 30px 50px 30px;
  font-size: 20px;
  @media only screen and (min-width: 720px) {
    padding: 0 50px 50px 50px;
  }
  @media only screen and (min-width: 1100px) {
    padding: 0 200px 50px 200px;
  }
  @media only screen and (min-width: 1300px) {
    padding: 0 300px 50px 300px;
  }
  .title {
    font-family: 'Writable Story', Helvetica, Arial, sans-serif;
    font-size: 50px;
    @media only screen and (min-width: 800px) {
      font-size: 60px;
    }
  }
}
.content {
  display: flex;
  flex-wrap: wrap;
  padding: 0 30px;
  @media only screen and (min-width: 1100px) {
    padding: 0 150px;
  }
  @media only screen and (min-width: 1100px) {
    padding: 0 190px;
  }
  .card {
    display: flex;
    flex-basis: calc(50% - 30px);
    align-items: center;
    margin: 15px;
    cursor: pointer;
    border-radius: 25px;
    transition: all 0.2s linear;

    @media only screen and (min-width: 720px) {
      flex-basis: calc(33% - 30px);
    }
    @media only screen and (min-width: 1100px) {
      flex-basis: calc(25% - 30px);
    }

    .img-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .background-image {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
      }
      .parent-image {
        z-index: 2;
      }
      img {
        width: 100%;
      }
    }
    @media only screen and (min-width: 1100px) {
      &:hover {
        transform: translateY(-20px);
        .parent-image {
          z-index: 0;
        }
        .background-image {
          z-index: 1;
        }
      }
    }
  }
}
.subtitle {
  z-index: 1;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: 'Brandon Grotesque Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  opacity: 0.5;
}
</style>
